import { useState, useRef, useEffect } from "react"
import swal from 'sweetalert2'
import {url, urlT} from "../Const"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage,faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {Foto} from "../albunes/Foto"
import Resizer from "react-image-file-resizer"

export function AlbunesImagenes(props){
    //console.log(props.id)
    const id = props.id
    const refImagen = useRef()
    const [file,setFile] = useState([]);
    const [mostrar,setMostrar] = useState(false)
    const [filename, setFilename] = useState()
    //enviar imagen
    const handleSubmit = (ev) =>{
        ev.preventDefault()
        console.log("archivo:")
        //console.log(file)
        fetch(url + "/fotos/images/nombre/" + id)
        .then(response => response.json())
        .then(dataf => { console.log("dataf",dataf)
            if(dataf.msj == 'ok'){
                setFilename(dataf.name)
                const data = new FormData()
                data.append('upfile', file)
                data.append('album', id)
                data.append('filename',dataf.name)
                //console.log("upfile:",data)
            
                const token = localStorage.getItem('token')
                const options = {
                    method : "POST",
                    headers : {
                        "x-auth-token" : token,
                    },
                    body: data
                    // body : JSON.stringify(data)
                }
                console.log("here")
                // fetch(url + "/fotos/images/"+id,options)
                // const url = "https://www.fotomedios.com/selecta/files/index.php"
                fetch(urlT + "/index.php",options)
                .then(response => response.json())
                .then(data => { 
                    console.log("realizado")
                    console.log(data)
                    // if(data.msj == "ok"){
                    //     swal.fire("Imagen  Cargada con Exito")
                    //     imagenesListar(id)
                    //     refImagen.current.value = ""
                    // }          
                    // else{
                    //     swal.fire({
                    //         title: "Problema Carga",
                    //         text: data.msj,
                    //         icon: "warning"
                    //     })
                    //     //alert(data.msj)
                    //     console.log(data.err)
                    // }
                })
            }
        })
    }

    //obtener las imagenes de una album
    const [ items,setItems] = useState([])
    useEffect(()=>{
        imagenesListar(id)
    }, [])

    // useEffect(()=>{
    //     imagenesListar(id)
    // }, [items])

    function imagenesListar(id){ console.log("imgs listar...")
        fetch(url + "/fotos/album/" + id)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
    }

    const handleEliminar= (id)=>{
        swal.fire({
            title: 'Desea eliminar la imagen del album?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            customClass: {
              actions: 'my-actions',
              cancelButton: 'order-1 right-gap',
              confirmButton: 'order-2',
              denyButton: 'order-3',
            }
          }).then((result) => {
            if (result.isConfirmed) {
                eliminaImagen(id)
              
            }
          })
    }

    function eliminaImagen(foto_id){
        const item = {
            "id" : foto_id,
        }
        console.log(item)
        const options = {
            method : "DELETE",
            headers : {
                "Content-Type" : "application/json"
                //,"x-auth-token" : token
            },
            body : JSON.stringify(item)
        }
        fetch(url + "/fotos/" + foto_id,options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok"){
                swal.fire('Eliminar Fotografia', data.msj, 'info')
                imagenesListar(id)
            }else{
                swal.fire('Eliminar Fotografia', data.msj, 'danger')
            }
        })
    }

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        },
        'base64'
        );
    });
    
    // const handleChange = (ev)=>{
    //     console.log(ev.target.files)
    //     setFile(ev.target.files[0])
    //     setMostrar(true)
    // }
    const handleChange = async (event) => {
        try {
            const file = event.target.files[0];
            const image = await resizeFile(file);
            setFile(image)
            console.log(image);
            setMostrar(true)
        } catch(err) {
            console.log(err);
        }
    }
    
    return <>
            <form>
            <h3>Imagenes</h3>
            <div className="container">
                <div className="row">
                    <div className="col-4">

                    </div>
                    <div className="col-8">
                        <form role="form" name="ff" >
                            <input type="file"
                                name="fileupload"
                                onChange={handleChange}
                                ref={refImagen}
                             />
                             { mostrar ?
                                <button type="submit" className="btn btn-info"  value="Agregar" onClick={handleSubmit} >Subir</button>
                                :
                                <div></div>
                             }
                            
                        </form>
                    </div>
                </div>
                <div class="row py-5">
                    { items.map(dato=>(
                    <div class="col-3 py-2">
                        <div className="card">
                            {/* <img className="card-img-top" src={`http://localhost:3000/images/${ dato._id }.jpg`} alt="Imagen"></img> */}
                            <img className="card-img-top" src={`${urlT}/images/${ id }/${ dato._id }.jpg`} alt="Imagen"></img>
                            <div className="card-body">
                                <p className="card-text"> <FontAwesomeIcon icon={faTrashAlt} size = '2x' swapOpacity onClick={()=>handleEliminar(dato._id)}  /></p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        
        </form>
    </>
}