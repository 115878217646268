import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import './LikeButton.css'; // Import the CSS file for styling
import {LikeButton} from './LikeButton'
import { url,urlT } from '../Const'
import {ZoomModal} from "./ZoomModal";
export function Foto(props){
    const [zoomImgUrl, setZoomImgUrl] = useState(null);

    const [urlN, setUrlN] = useState();
    useEffect(()=>{
        setUrlN(url.substring(0,url.length-4)+"/selecta")
    },[])
    
    const openZoomModal = (imgUrl) => {
        setZoomImgUrl(imgUrl);
    };

    const closeZoomModal = () => {
        setZoomImgUrl(null);
    };
    const valor = 1
    const handleSeleccion = () =>{
        console.log("sele....")

        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            }
            //,body : JSON.stringify(item)
        }

        fetch(url + "/fotos/" + props.id,options)
        .then(response => response.json())
        .then(data => { console.log(data)
            //if(data.msj == "ok")
                //swal.fire("foto Actualizada con Exito")
            //else
            //    swal.fire(data.msj)
            props.sendData(valor)
        })
    }
    return <>
        <div className="card" style={{width: "18rem"}}>
        <img className="card-img-top" src={urlT + `/images/${props.album}/${ props.id }.jpg`} alt="Imagen" onClick={() => openZoomModal(urlT + '/images/'+props.album+'/'+props.id +'.jpg')}></img>
            <div className="card-body">
                <h5 className="card-title"></h5>
                <p className="card-text"></p>
            </div>
            <ul className="list-group list-group-flush">
                {/* <li className="list-group-item">id: { props.id} </li> */}
                <li className="list-group-item" onClick={handleSeleccion}>
                    <div className='row'>
                        <div className='col font-link'>
                        Seleccionado:  
                        </div>
                        <div className='col'>
                                <LikeButton seleccionado={ props.seleccionado }></LikeButton>
                        </div>
                    </div>    
                </li>
            </ul>
            {/* <div className="card-body">
            <button className="btn btn-info">
                <FontAwesomeIcon icon={faImage} />
            </button>
            </div> */}
        </div>
        {zoomImgUrl && (
        <ZoomModal imgUrl={zoomImgUrl} closeModal={closeZoomModal} />
      )}
    </>

}