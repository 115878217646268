import { useEffect,useState } from "react"
import { Link } from 'react-router-dom'
import Swal from "sweetalert2"
import { url } from "../Const"
export function AlbunesList(){
    const [items, setItems] = useState([])
    useEffect(()=>{
        const id = localStorage.getItem("user_id")
        fetch(url + "/albunes/usuario/" + id)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
    }, [])

    const handleCopy = (text) => {
        text = "http://localhost:8009/album/" + text
        //text = url + "/album/" + text
        navigator.clipboard.writeText(text);
        Swal.fire({
            title : "Enlace",
            text : 'Enlace Copiado!',
            icon : "info"
        })
      };
    return <>
        {/* <div><Link to={`/tablero/albunescreate/`} className="btn btn-success" >Nuevo</Link></div> */}
        <table className="table">
            <tr>
                <th>Creado</th>
                <th>Cliente</th>
                <th>Titulo</th>
                <th>Estado</th>
                <th>Compartir</th>
            </tr>
            { items.map(dato=>(
                <tr>
                    <td>{ (dato.fecha).substring(0,10) }</td>
                    <td>{ dato.cliente_nombre }</td>
                    <td>{ dato.titulo }</td>
                    <td><Link to={`/tablero/AlbunesEdit/${dato._id}`}>Editar</Link></td>
                    <td><a href="#" onClick={ () => handleCopy(dato._id)  }>Compartir</a></td>
                </tr>
            )) }

        </table>
    </>
}