import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import swal from 'sweetalert2'
export function ClientesEdit(){
    const refNombre = useRef()
    const refContacto = useRef()
    const refCorreo = useRef()
    const {id} = useParams()
    const [items,setItems] = useState([])
    console.log(id)
    useEffect(()=>{
        fetch("http://localhost:3000/api/clientes/" + id)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            refNombre.current.value = data.nombre
            refContacto.current.value = data.contacto
            refCorreo.current.value = data.correo
        })

        //listado de albunes del cliente
        fetch("http://localhost:3000/api/albunes/cliente/" + id)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })

    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "nombre" : refNombre.current.value,
            "correo": refCorreo.current.value,
            "contacto" : refContacto.current.value
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch("http://localhost:3000/api/clientes",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.msj == "ok")
                swal.fire("Cliente Actualizad@ con Exito")
            else
                swal.fire(data.msj)
        })
    }
    return <>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="texto" className="form-control" ref={ refNombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Contacto
                </label>
                <input type="texto" className="form-control" ref={ refContacto } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Correo
                </label>
                <input type="texto" className="form-control" ref={ refCorreo } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>

        </form>
        <div className="container">
            <div className="row">
                <Link to={`/tablero/albunesCreate/${id}`} className="btn btn-success">Nuevo Album</Link>
            </div>
            <h3>Albunes</h3>
            <table className="table">
            <tr>
                <th>Creado</th>
                <th>Cliente</th>
                <th>Estado</th>
            </tr>
            { items.map(dato=>(
                <tr>
                    <td>{ dato.fecha }</td>
                    <td>{ dato.titulo }</td>
                    <td><Link to={`/tablero/AlbunesEdit/${dato._id}`}>Editar</Link></td>
                </tr>
            )) }

        </table>
        </div>
    </>
}