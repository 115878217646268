import React from "react";
import "./ZoomModal.css"; // import CSS file for styling

export function ZoomModal ({ imgUrl, closeModal }) {
  return (
    <div className="zoom-modal">
      <div className="zoom-modal-content">
        <img src={imgUrl} alt="Zoomed" className="zoom-modal-img" />
        <button className="zoom-modal-close" onClick={closeModal}>
          [X] Cerrar 
        </button>
      </div>
    </div>
  );
};