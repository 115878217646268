import { useEffect, useState } from "react"
import { useNavigate, Routes, Route, Link } from "react-router-dom"
import { ClientesList } from '../clientes/ClientesList'
import { ClientesEdit } from "../clientes/ClientesEdit"
import { ClientesCreate} from "../clientes/ClientesCreate"
import { AlbunesList } from "../albunes/AlbunesList"
import { AlbunesEdit } from "../albunes/AlbunesEdit"
import { AlbunesCreate } from "../albunes/AlbunesCreate"
import { AlbunesImagenes} from "../albunes/AlbunesImagenes"
import { UsuariosList} from "../usuarios/UsuariosList"
import { MenuTablero } from "../componentes/MenuTablero"
export function Tablero(){
    const navigate = useNavigate()
    const [name, setName]= useState();
    useEffect(()=>{
        try{
            const token = localStorage.getItem("token")
            if(!token)
                navigate("/login")
            else{
                console.log(token)
                setName(localStorage.getItem("nombre"))
            }
                
        }catch(err){
            navigate("/login")
        }
    }, [])

    const handleCerrar = ()=>{
        localStorage.removeItem("token")
        navigate("/")
    }
    return <>
    <MenuTablero></MenuTablero>
    
    {/* <div className="container"> */}
        <div className="row bg-warning">
            <div className="col-3 my-3 py-3">
                <div className="container d-flex justify-content-center">
                    <div className="col-lg-6">
                        <img src={"/images/person.png"} width={100}></img>
                        <h2>{ name }</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="row px-5 py-3">
                        <h1 class="d-flex align-items-center fs-4 text-white mb-0 font-link" >Opciones</h1>
                        <div className="py-3" >
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li className="nav-item font-link"><Link to="/tablero/clientesList" className="nav-link text-white" aria-current="page"><h3>Clientes</h3></Link></li>
                                <li className="nav-item font-link"><Link to="/tablero/albunesList" className="nav-link text-white"><h3>Albunes</h3></Link></li>
                                {/* <li><Link to="/tablero/usuariosList">Usuarios</Link></li> */}
                                <li className="nav-item font-link py-4"><Link to="/" className="nav-link text-white">Salir</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
                
            </div> 
            <div className="col-9 bg-light">
                <div className="container">
                    <h1 className="font-link">Panel de Administración</h1>
                    <Routes>
                        <Route path="/clientesList" element={ <ClientesList/>}></Route>
                        <Route path="/clientesEdit/:id" element={ <ClientesEdit/> }></Route>
                        <Route path="/clientesCreate" element={ <ClientesCreate/>}></Route>
                        <Route path="/albunesList" element={ <AlbunesList /> }></Route>
                        <Route path="/albunesEdit/:id" element={ <AlbunesEdit/> }></Route>
                        <Route path="/albunesCreate/:id" element={ <AlbunesCreate/>}></Route>
                        <Route path="/albunesImagen/" element={ <AlbunesImagenes/>}></Route>
                        {/* <Route path="/usuariosList" element={ <UsuariosList/> }></Route> */}
                    </Routes>
            </div>
                </div>
            
        </div>
    {/* </div> */}
    {/* <button onClick={ handleCerrar } > Cerrar Sesión </button> */}
    </>
}