import { useEffect,useState } from "react"
import { Link } from 'react-router-dom'
export function ClientesList(){
    const [items, setItems] = useState([])
    useEffect(()=>{
        fetch("http://localhost:3000/api/clientes")
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
    }, [])
    return <>
        <div><Link to="/tablero/ClientesCreate" className="btn btn-success">Nuevo Cliente</Link></div>
        <table className="table">
            <tr>
                <th>Nombre</th>
                <th>Contacto</th>
                <th>Opciones</th>
            </tr>
            { items.map(dato=>(
                <tr>
                    <td>{ dato.nombre }</td>
                    <td>{ dato.contacto }</td>
                    <td><Link to={`/tablero/ClientesEdit/${dato._id}`}>Editar</Link></td>
                </tr>
            )) }

        </table>
    </>
}