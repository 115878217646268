import { useEffect } from "react"
import { useNavigate, Routes, Route, Link } from "react-router-dom"
import { UsuariosList} from "../usuarios/UsuariosList"
import { MenuTablero } from "../componentes/MenuTablero"
import { UsuariosEdit } from "../usuarios/UsuariosEdit"
import jwt_decode from 'jwt-decode'
export function TableroAdmin(){
    const navigate = useNavigate()

    useEffect(()=>{
        try{
            const token = localStorage.getItem("token")
            if(!token)
                navigate("/login")
            else{
                const decoded = jwt_decode(token)
                if(decoded.usuario.perfil=="admin"){
                    navigate("/tableroAdmin")
                }else
                    navigate("/tablero")
            }
        }catch(err){
            navigate("/login")
        }
    }, [])

    const handleCerrar = ()=>{
        localStorage.removeItem("token")
        navigate("/")
    }
    return <>
    <MenuTablero></MenuTablero>
        <div className="row bg-secondary">
            <div className="col-3 my-3 ">
                <div className="container">
                    <div className="row px-5">
                        <h1 class="d-flex align-items-center fs-4 text-white mb-0">Opciones</h1>
                        <div className="py-3" >
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li className="nav-item"><Link to="/tableroAdmin/usuariosList" className="nav-link text-white" aria-current="page">Usuarios</Link></li>
                                <li className="nav-item"><Link to="/" className="nav-link text-white">Salir</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="col-9 bg-light">
                <div className="container">
                    <h1>Administrador</h1>
                    <Routes>
                        <Route path="/usuariosList" element={ <UsuariosList/> }></Route>
                        <Route path="/usuariosEdit/:id" element={ <UsuariosEdit/> }></Route>
                    </Routes>
                </div>
            </div>
        </div>
    </>
}