import { useRef, useState, useEffect} from 'react'

export function Test(){
	const correoRef = useRef()
	const contrasenaRef = useRef()


	useEffect(()=>{
		/* global google */
		google.accounts.id.initialize({
			client_id : "992321661518-0odvtl0v1aohg8t4qghqmphafcdsa6mq.apps.googleusercontent.com",
			callback : handleCallbackResponse,
			cookiepolicy: "single_host_origin"
		})

		google.accounts.id.renderButton(
			document.getElementById("signInDiv"),
			{ theme: "outline", size : "large"}
		)
	},[])

	function handleCallbackResponse(response){
		console.log("JWT:", response.credential)
	}

	const handleChange = (ev)=>{ console.log(ev.target.value) }
	const handleSubmit = (ev) =>{
		ev.preventDefault()
	} 
	
    return <>
        <div className='container'>
        <div className='row my-5'>
			<div className="container-fluid">
	<div className="row">

	
			<div id="signInDiv"></div>
		
	</div>
</div>
	</div>
	</div>
</>
}