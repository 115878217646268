import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare,faRegular, faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import './LikeButton.css'; // Import the CSS file for styling

export function LikeButton (props){
  const [liked, setLiked] = useState(false); // Using useState to manage the like status

  useEffect(()=>{
    setLiked(props.seleccionado)
  },[])

  const handleLike = () => {
    setLiked(!liked); // Toggle the liked state
  };

  return (
    <div>
      {
      liked ? (<>
      {/* <button className="btn btn-success"> */}
        <FontAwesomeIcon icon={faSquareCheck} size = '5x'  onClick={handleLike} style={{"--fa-primary-color": "gold"}}/>
      {/* </button> */}
      </>):(<>
        <FontAwesomeIcon icon={faSquare} size = '5x' swapOpacity onClick={handleLike} />
      </>)

    }
    </div>
  );
}