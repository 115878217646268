import { useEffect,useRef, useState } from "react"
import { useParams } from "react-router-dom"
import swal from 'sweetalert2'
import { Selector } from "../componentes/Selector"
import { AlbunesImagenes, Imagenes } from "./AlbunesImagenes"
import { Link } from "react-router-dom"
import { url } from "../Const"
export function AlbunesCreate(){
    const refTitulo = useRef()
    const refFecha = useRef()
    const refCantidad = useRef()
    const {id} = useParams()
    console.log(id + "<<")
    useEffect(()=>{
        // fetch("http://localhost:3000/api/items/" + id)
        // .then(response => response.json())
        // .then(data =>{ console.log(data)
        //     refTitulo.current.value = data.titulo
        //     refImagen.current.value = data.imagen
        // })
    },[])

    const [ mostrar,setMostrar] = useState(false)

    const handleSubmit =()=>{ console.log("album Guardando")
        const user_id = localStorage.getItem("user_id")
        const item = {
            "cliente_id" : id,
            "titulo" : refTitulo.current.value,
            "fecha": refFecha.current.value,
            "cantidad" : refCantidad.current.value,
            "usuario_id" : user_id
        }

        const token = localStorage.getItem('token')
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/albunes",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.msj == "ok"){
                swal.fire("Album Creado con Exito")
                setMostrar(true)
            }
            else
                swal.fire(data.msj)
        })

        
    }
    return <>
        <form name="tt">
        <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cliente
                </label>
                <Selector clienteId={id} ></Selector>
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Titulo
                </label>
                <input type="text" className="form-control" ref={ refTitulo } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Fecha
                </label>
                <input type="date" className="form-control" ref={ refFecha } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Cantidad
                </label>
                <input type="text" className="form-control" ref={ refCantidad } />
            </div>
            <div class="form-group my-4">

                { !mostrar ?
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
                : null }
			</div>

        </form>
       { mostrar ? <AlbunesImagenes id={ id }></AlbunesImagenes> : null }
       {/* <Link to="/tablero/albunesImagen/">llenar</Link> */}
    </>
}