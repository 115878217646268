import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Menu } from "./componentes/Menu";
import {url} from './Const'
export function Principal(){
    const [ items, setItems] = useState([])
    function listar(){
        console.log("Listando Peliculas...")
        fetch(url +"/items")
        .then(response => response.json())
        .then(data =>{ console.log(data)
                setItems(data)
        })
    }
    useEffect(()=>{ listar() }, [])
    return <>
        <Menu></Menu>
        <section className='bg-primary'>
          <div className='container'>
            <div className='row'>
            <div class="col-lg-6">
                <div className="p-1 mb-1 rounded-3" >
                  <div class="container-fluid py-5">
                    <div className='d-inline-flex'>
                      <h1 class="display-5 fw-bold py-3 font-link text-white"  style={{fontSize:75}}>Selecta</h1>
                      <img src={"images/logo.png"} className='px-3' width={160} />
                    </div>
                    
                    <p class="col-md-10 fs-1 font-link text-warning">Permite que tus clientes seleccionen las mejores fotografías.</p>
                    <button class="btn btn-danger btn-lg" type="button">Conoce más</button>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 order-1 hero-img py-5">
                <img className='img-fluid' src={"images/banner_01.png"}/>
              </div>
            </div>
          </div>
        </section>
        
        <section>
          <div className="container">
            <div className="row py-5" >
              <div className="col-lg-8 order-0 hero-img" >
                <div style={{backgroundImage: `url("images/banner_02.png")`, height:500}}>

                </div>
              </div>
              <div className="col-lg-4 py-5">
                <h1 className='py-3 font-link px-3'>Orientado a fotógrafos que requieran disponer de una herramienta para sus clientes. </h1>
              </div>
            </div>
          </div>
        </section>
        <section style={{backgroundImage: `url("images/banner_03.png")`, height:650}}>
          <div className="container" >
            <div className="row" >
              <div className="col-lg-8 order-0 hero-img" >
                <div >

                </div>
              </div>
              {/* <div className="col-lg-4 py-5">
                <h3 className='py-3'>Orientado a fotógrafos que requieran disponer de una herramienta para sus clientes. </h3>
              </div> */}
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row" style={{backgroundImage: `url("images/banner_04.png")`,backgroundRepeat:'no-repeat', height:500}}>
              <div className="col-lg-8 order-0 hero-img" >
                <div >

                </div>
              </div>
              <div className="col-lg-4 py-5">
                <h1 className='py-3 font-link px-3'>Orientado a fotógrafos que requieran disponer de una herramienta para sus clientes. </h1>
              </div>
            </div>
          </div>
        </section>
        <section className='bg-warning'>
          <div className='container'>
            <div className='row py-3 align-items-center'>
              <div className='col-lg-6 text-center px-2'>
                <Link to="/registro" type="button" class="btn btn-lg btn-success shadow-lg font-link" style={{ height: 100,width: 300, fontSize:50 }} disabled>Registrate</Link>
              </div>
              <div className='col-lg-6'>
                <img className='img-fluid' src={'images/banner_05.png'}/>
              </div>
            </div>
          </div>
        </section>
        <footer className='bg-dark pt-5 font-link text-white' style={{height:200}}>
          <div class="row">
            <div className="col-lg-4 fs-1 pl-5" style={{ paddingLeft:50 }}>
              Selecta
              <img src={"images/logo.png"} className='px-3' width={100} />
              {/* <img clasName="px-3" src={"favicon.ico"} /> */}
            </div>
            <div className="col-lg-4 fs-3">
              <div className='pl-3'>
                Herramienta de seleccion fotografica para fotografos
              </div>
              
            </div>
            <div className="col-lg-4 fs-3 pl-5">
              <ul>
                <li>
                  @fotomedios
                </li>
                <li>
                  info@fotomedios.com
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </> 
}