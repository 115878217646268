import { useState } from 'react'
import { Link } from 'react-router-dom'
export function MenuTablero(){
    // const [estado, setEstado] = useState(false)
    // try{
    //     if(localStorage.getItem('token'))
    //         setEstado(true)
    // }catch(err){
    //     console.log("NO token")
    // }
    return  <nav className="navbar navbar-ligh bg-primary" >
    <div className="container-fluid">
         <div className="navbar-header">
        <Link className="btn btn-outline-danger navbar-brand mr-0 mr-md-2" to="/" aria-label="Bootstrap">V</Link>
            <Link className="navbar-brand" to="/">Inicio</Link>
              {/* { estado ? (<Link  className='navbar-brand' to="/tablero">Administrador</Link>) : ("") }  */}
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">    
            <ul className="nav navbar-nav navbar-right">

          
            </ul>
        </div>
            <div className="nav-item dropdown my-2 my-sm-0">
                <Link className="btn btn-outline-warning" to="/login">Login</Link>
            </div>
        </div>
    </nav>
}