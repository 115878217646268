import React, { useEffect, useRef, useState } from "react";
import {useNavigate } from 'react-router-dom'
import { Menu } from "../componentes/Menu";
import { url } from "../Const";
import './style.css'
import Recaptcha from "react-google-recaptcha"
import swal from 'sweetalert2' 
export function Registro (){
    const apikey = "6LeixmYlAAAAAHHXNad99Kky-yy8bpm-Ja7292_c" 
    const navigate = useNavigate()
    const captchaRef = useRef()
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [captcha, setCaptcha] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(()=>{
        //setIsSubmitting(false)
        console.log("submit",isSubmitting)
    },[])
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCaptchaChange = (event) => {
        console.log("cambiado")
        setCaptcha(event.target.value);
    };

  //const handleSubmit = async (event) => { console.log("guardando")
    const handleSubmit = (event) => { console.log("guardando")
    let msj = ""
    //setIsSubmitting(true) //bloquear el boton de envio
    // Simulate an asynchronous API call
    setTimeout(() => {
        setIsSubmitting(true);
    }, 200);
    event.preventDefault();
    
    const token = captchaRef.current.getValue();
    if(!token){
        console.log("token",token)

        try {
            var myHeaders = new Headers();
            myHeaders.append("apikey", "DKbdZTjfwpznvVi5oC4P3ZEOwdgroD1o");
            
            var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders
            };
        
            fetch("https://api.apilayer.com/email_verification/check?email="+email, requestOptions)
            .then(response=>response.json())
            .then(data=>{ console.log(data)
                if (data.format_valid) {
                        console.log("Registration form submitted!");
                        
                        const usuario = {
                            "nombre" : name,
                            "correo" : email 
                        }

                        const options = {
                            method : "POST",
                            body : JSON.stringify(usuario),
                            headers : {
                                'Content-Type' : 'application/json'
                            }
                        }
                
                        fetch(url + '/usuarios/registro',options)
                        .then(response =>response.json())
                        .then(data => { console.log(data)
                            let icon = "danger"
                            if(data.response == "ok"){
                                icon = 'success'
                                setTimeout(() => {
                                    setIsSubmitting(true);
                                  }, 200);
                            }else{
                                setTimeout(() => {
                                    setIsSubmitting(false);
                                  }, 200);
                            }
                            swal.fire({
                                title: "Registro",
                                text: data.msj,
                                icon: "success"
                            })
                            
                        })
                        .catch(err => console.log(err))
                    } else {
                        msj = "Por favor Verifica la direccion de Correo Electrónico."
                        setErrorMessage(msj)
                        swal.fire({
                            title: "Registro",
                            text: msj,
                            icon: "danger"
                        })
                    }
            })
        } catch (error) {
        console.error(error);
        msj = "Ha ocurrido un error validando el email. Por favor intentalo mas tarde."
        swal.fire({
            title: "Registro",
            text: msj,
            icon: "danger"
        })
        setErrorMessage(msj);
        } finally {
            setIsSubmitting(false);
        }
    }
  };

  return (<>
    <Menu></Menu>
    <div className="registration-form-container">
    <form onSubmit={handleSubmit} className="registration-form">
      <div>
        <label htmlFor="name">Nombre:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={handleNameChange}
          required
        />
      </div>

      <div>
        <label htmlFor="email">Correo Electrónico:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
      </div>

      <div>
        <label htmlFor="captcha">Captcha:</label>
            <Recaptcha
                sitekey={apikey}
                ref={captchaRef}
            />
      </div>

      <div>
        <button type="submit" disabled={isSubmitting}>
          Enviar
        </button>
      </div>

      {errorMessage && <div>{errorMessage}</div>}
    </form>
    </div>
    </>);
}