import { useEffect,useRef } from "react"
import { useParams } from "react-router-dom"
import swal from 'sweetalert2'
export function ClientesCreate(){
    const refNombre = useRef()
    const refContacto = useRef()
    const refCorreo = useRef()
    // const {id} = useParams()
    // console.log(id)
    useEffect(()=>{
        // fetch("http://localhost:3000/api/clientes/" + id)
        // .then(response => response.json())
        // .then(data =>{ console.log(data)
        //     refNombre.current.value = data.nombre
        //     refContacto.current.value = data.contacto
        //     refCorreo.current.value = data.correo
        // })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "nombre" : refNombre.current.value,
            "correo": refCorreo.current.value,
            "contacto" : refContacto.current.value
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch("http://localhost:3000/api/clientes",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.msj == "ok"){
                refNombre.current.value  = ""
                refCorreo.current.value = ""
                refContacto.current.value =  ""
                swal.fire("Cliente Almacenad@ con Exito")
            }else
                swal.fire(data.msj)
        })
    }
    return <>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="texto" className="form-control" ref={ refNombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Contacto
                </label>
                <input type="texto" className="form-control" ref={ refContacto } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Correo
                </label>
                <input type="texto" className="form-control" ref={ refCorreo } />
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>

        </form>
    </>
}