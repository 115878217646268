import { useEffect,useState } from "react"  
import { useParams } from 'react-router-dom'
import { Foto } from "./Foto"  
import { url } from "../Const"
//componente para ver el album de lado del cliente
export function Album(){
    const {id} = useParams()
    let [conteo,setConteo]  = useState(0)
    let [cantidad, setCantidad] = useState(0)
    let [titulo, setTitulo] = useState("")
    //obtener las imagenes de una album
    const [ items,setItems] = useState([])
    useEffect(()=>{
        //obtener las fotos del album
        fetch(url +"/fotos/album/" + id)
        .then(response => response.json())
        .then(data => { console.log("album") 
            console.log(data)
            setItems(data)
            setCantidad(data.cantidad)
            seleccionadas(id) //supuesto conteo
            
        })

        //obtener los datos del album
        fetch(url + "/albunes/" + id)
        .then(response => response.json())
        .then(data => { console.log(data)
            setCantidad(data.cantidad)
            setTitulo(data.titulo)
        })
    }, [])
    
    function seleccionadas(id){
        fetch(url + "/fotos/album/seleccionadas/" + id)
        .then(response => response.json())
        .then(data => {  console.log("seleccionadas:" +data)
            setConteo(data)
        })
    }

    const getData =(val)=>{
        conteo +=val
        seleccionadas(id)
        console.log(conteo)
    }
return <>
        <div className="container my-3">
            <div className="row">
                <div className="col-8">
                    <h5>Album</h5>
                    <h2 className="font-link">{titulo}</h2>
                </div>
                <div className="col-4">
                    <h5 className="font-link">seleccionadas { conteo } / {cantidad}</h5>
                    
                </div>
                {/* <FotoData sendData={getData}></FotoData> */}
            </div>
            <div className="row">
            { items.map(data=>(
                <div className="col" key={data._id} >
                     <Foto key={data._id} id={data._id} album={id} seleccionado={data.seleccionado} sendData={getData}></Foto>
                </div>
                )) }
            </div>
        </div>
</>
}