import { useEffect,useState } from "react"
import { Link } from 'react-router-dom'
export function UsuariosList(){
    const [items, setItems] = useState([])
    useEffect(()=>{
        fetch("http://localhost:3000/api/usuarios")
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
    }, [])
    return <>
        <div><a href="" className="btn btn-success" >Nuevo</a></div>
        <table className="table">
            <tr>
                <th>Creado</th>
                <th>Cliente</th>
                <th>Estado</th>
            </tr>
            { items.map(dato=>(
                <tr>
                    <td>{ dato.correo } </td>
                    <td>{ dato.nombre }</td>
                    <td>{ dato.estado ? (<label>Activo</label>) : (<label>Inactivo</label>) }</td>
                    <td><Link to={`/tableroAdmin/usuariosEdit/${dato._id}`}>Editar</Link></td>
                </tr>
            )) }

        </table>
    </>
}