import { useRef, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Menu } from '../componentes/Menu' 
import swal from 'sweetalert2'
import { url } from '../Const'
//import jwt_decode from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';
import buffer from 'buffer';
global.Buffer = buffer.Buffer; 


export function Login(){
	const correoRef = useRef()
	const contrasenaRef = useRef()
	const [mensaje,setMensaje] = useState()
	const navigate = useNavigate()


	useEffect(()=>{
		/*global google */

		if(url != "http://localhost:3000/api"){
			google.accounts.id.initialize({
				client_id : "992321661518-0odvtl0v1aohg8t4qghqmphafcdsa6mq.apps.googleusercontent.com",
				callback : handleCallbackResponse,
				cookiepolicy: "single_host_origin"
			})
	
			google.accounts.id.renderButton(
				document.getElementById("signInDiv"),
				{ theme: "outline", size : "large"}
			)
		}
		
	},[])

	function handleCallbackResponse(response){
		console.log("JWT:", response.credential)
	}

	const handleChange = (ev)=>{ console.log(ev.target.value) }
	const handleSubmit = (ev) =>{
		ev.preventDefault()
	} 
	const handleClick =()=>{
		console.log("validado login...")
		// let correo = document.frm.correo.value 
		let correo = correoRef.current.value
		let contrasena = contrasenaRef.current.value
		console.log("correo.." +correo + ", " + contrasena)
		const usuario = { 
			'correo' : correo,
			'contrasena' : contrasena
		}

		const options = {
			method : "POST",
			body : JSON.stringify(usuario),
			headers : {
				'Content-Type' : 'application/json'
			}
		}

		fetch(url + '/usuarios/login',options)
		.then(response => response.json())
		.then(data => { console.log(data)
			if(data.response == "ok"){
				const decoded = jwt_decode(data.token)
				console.log("!!",decoded)
				if(decoded.usuario.perfil=="admin"){
					navigate("/tableroAdmin")
				}else
					navigate("/tablero")
				localStorage.setItem("token",data.token)
				localStorage.setItem("nombre",decoded.usuario.nombre)
				localStorage.setItem("user_id",decoded.usuario.id)
			}else{
				swal.fire({
							title: "Acceso",
							text: data.msj,
							icon: "warning"
						})
			}
		})
		.catch(err => console.log(err))
	}
    return <>
		<Menu></Menu>
        <div className='container'>
        <div className='row my-5'>
			<div className="container-fluid d-flex justify-content-center">
				<div className="row d-flex jusfity-content-center">
					<div className="col-md-4 offset-4">
						<img src={"images/logo.png"} className='px-3' width={160} />
					</div>
					<div className="col-md-4 offset-4">
						
						{mensaje}
						<form name="frm" onSubmit={handleSubmit}>
							<div className="form-group"> 
								<label htmlFor="exampleInputEmail1">
									Email address
								</label>
								<input type="email" name="correo" ref={ correoRef }className="form-control" id="exampleInputEmail1" onChange={ handleChange }/>
							</div>
							<div className="form-group">
								<label htmlFor="exampleInputPassword1">
									Password
								</label>
								<input type="password" ref={ contrasenaRef} className="form-control" id="exampleInputPassword1" />
							</div>
							<div className="form-group my-4">
								<button type="button" className="btn btn-primary" onClick={ handleClick } >
									Acceder
								</button>
							</div>				
						</form>
						{mensaje}

					</div>
					<div className="col-md-4 offset-4 d-flex jusfity-content-center">
						- ó -
						
					</div>
					<br></br>
					<div className="col-md-4 offset-4">
						<div>
							<div id="signInDiv"></div>
						</div>
					</div>
				</div>
			</div>
	</div>
	</div>
</>
}