import { useEffect, useState, useRef } from "react"
export function Selector(props){
    const [items, setItems] = useState([])
    console.log(props.clienteId + "<<")
    // const refClienteId = useRef()
    const refClienteNombre = useRef("hi")
     useEffect(()=>{
        
        //obtener el dato de un cliente
        fetch("http://localhost:3000/api/clientes/" + props.clienteId)
        .then(response => response.json())
        .then(datap => { console.log(datap)
            fetch("http://localhost:3000/api/clientes")
            .then(response => response.json())
            .then(data => { console.log(data)
                const cliente = { _id: datap._id
                                , nombre : datap.nombre  }
                data.unshift(cliente)
                setItems(data)
            })
        })
    }, [])
    return <> 
        <select className="form-select">
            {/* <option value="" ref={ refClienteNombre } >{ text }</option>  */}
            { items.map(dato=>(
                    <option value={dato.id }>
                        { dato.nombre } 
                    </option>
                )) }
        </select>
    </>
}