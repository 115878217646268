import React from 'react'
import ReactDom from 'react-dom/client'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import {Login} from './components/usuarios/Login'
import { Principal } from './components/Principal'
import { Tablero } from './components/Dashboard/Tablero'
import {TableroAdmin} from './components/Dashboard/TableroAdmin'
import { Album } from './components/album/Album'
import { Registro } from './components/registro/Registro'
import {Test} from './components/test/Test'
const root = ReactDom.createRoot(document.getElementById('root'))
root.render(<>
    <Router>
        
                <Routes>
                    <Route path="/login" element={<Login/> }></Route>
                    <Route path="/" element={<Principal/>}></Route>
                    <Route path="/tablero/*" element={<Tablero/>}></Route>
                    <Route path="/tableroAdmin/*" element={<TableroAdmin/>}></Route>
                    <Route path='/album/:id' element={ <Album/>}></Route>
                    <Route path='/registro' element={ <Registro/>}></Route>
                    <Route path='/test' element={ <Test/>}></Route>
                </Routes>
                
    </Router>
</>)