import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import swal from 'sweetalert2'
import { AlbunesImagenes } from "./AlbunesImagenes"
import { FotoData } from "./FotoData"
import { url } from "../Const"
export function AlbunesEdit(){
    const refTitulo = useRef()
    const refFecha = useRef()
    const refCantidad = useRef()
    const {id} = useParams()
    const [ items,setItems] = useState([])
    console.log(id)
    useEffect(()=>{
        //obtener los datos del album
        fetch(url +"/albunes/" + id)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            refTitulo.current.value = data.titulo
            refFecha.current.value = (data.fecha).substring(0,10)
            refCantidad.current.value =data.cantidad
        })

        //obtener las fotos  del album
        
        fetch(url + "/fotos/album/" + id)
        .then(response => response.json())
        .then(data => { console.log(data)
            setItems(data)
        })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "titulo" : refTitulo.current.value,
            "fecha": refFecha.current.value,
            "cantidad" : refCantidad.current.value
        }

        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch(url + "/albunes",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.msj == "ok")
                swal.fire("Album Actualizad@ con Exito")
            else
                swal.fire(data.msj)
        })
    }
    return <>
        <div className="container" >
            <div className="row">
            <form>
                <div class="form-group"> 
                    <label for="exampleInputEmail1">
                        Titulo
                    </label>
                    <input type="texto" className="form-control" ref={ refTitulo } />
                </div>
                <div class="form-group"> 
                    <label for="exampleInputEmail1">
                        Fecha
                    </label>
                    <input type="texto" className="form-control" ref={ refFecha } />
                </div>
                <div class="form-group"> 
                    <label for="exampleInputEmail1">
                        Cantidad de fotos a escoger
                    </label>
                    <input type="texto" className="form-control" ref={ refCantidad } />
                </div>
                <div class="form-group my-4">
                    <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                        Guardar
                    </button>
                </div>

            </form>
            </div>
            <div className="row">
                <AlbunesImagenes id={ id }></AlbunesImagenes>
                {/* <h1>Imagenes</h1>
                
                <div class="row">
                    { items.map(dato=>(
                    <div class="col-3">
                        <div className="card">
                            <img className="card-img-top" src={`http://localhost:3000/images/${ dato._id }.jpg`} alt="Imagen"></img>
                            <div className="card-body">
                                <p className="card-text"></p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div> */}
            </div>
        </div>
    </>
}