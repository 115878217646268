import { useEffect,useRef,useState } from "react"
import { useParams } from "react-router-dom"
import {Link} from 'react-router-dom'
import swal from 'sweetalert2'
export function UsuariosEdit(){
    const refNombre = useRef()
    const refContacto = useRef()
    const refCorreo = useRef()
    const ref_estado = useRef()
    const {id} = useParams()
    const [us_estado, setUs_estado] = useState([])
    console.log(id)
    useEffect(()=>{
        fetch("http://localhost:3000/api/usuarios/" + id)
        .then(response => response.json())
        .then(data =>{ console.log(data)
            refNombre.current.value = data.nombre
            refCorreo.current.value = data.correo
            setUs_estado(data.estado)
        })
    },[])

    const handleSubmit =()=>{
        console.log("Editando")
        const item = {
            "id" : id,
            "nombre" : refNombre.current.value,
            "correo": refCorreo.current.value,
            "estado" : ref_estado.current.value
        }
        console.log(item)
        const token = localStorage.getItem('token')
        const options = {
            method : "PUT",
            headers : {
                "Content-Type" : "application/json",
                "x-auth-token" : token
            },
            body : JSON.stringify(item)
        }

        fetch("http://localhost:3000/api/usuarios",options)
        .then(response => response.json())
        .then(data => { console.log(data)
            if(data.response == "ok")
                swal.fire(data.msj)
            else
                swal.fire(data.msj)
        })
    }
    const handleEstado = ()=>{
        if(ref_estado.current.value == 1){
            if(window.confirm("Esta seguro de Inactivar el usuario?") == true){
                alert("Nomina Abierta")
                setUs_estado(ref_estado.current.value)
            }else{
                setUs_estado(false)
            }
        }else{
            setUs_estado(ref_estado.current.value)
        } 
    }
    return <>
        <form>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Nombre
                </label>
                <input type="texto" className="form-control" ref={ refNombre } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Correo
                </label>
                <input type="texto" className="form-control" ref={ refCorreo } />
            </div>
            <div class="form-group"> 
                <label for="exampleInputEmail1">
                    Estado
                </label>
                <select className="form-select" ref={ref_estado} value={us_estado} onChange={handleEstado}>
                        <option value='true' >Activo</option>
                        <option value='false' >Inactivo</option>
                </select>
            </div>
            <div class="form-group my-4">
                <button type="button" className="btn btn-primary" onClick={ handleSubmit }>
                    Guardar
                </button>
			</div>

        </form>
    </>
}